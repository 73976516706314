#footer-h2 {
  color: #0CF0AF;
}

#footer-text:hover {
  color: #00B7F8;
}

.footer-container {
    background-color: #131313;
    padding: 5rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  #footer-logo {
    color: #fff;
    display: flex;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    font-weight: bold;
    background-color: #0CF0AF;
    background-image: linear-gradient(to top, #00B7F8, #00D9D9, #0CF0AF);
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
  }
  
  .footer-links {
    width: 100%;
    max-width: 1000px;
    display: flex;
    justify-content: center;
  }
  
  .footer-link-wrapper {
    display: flex;
  }
  
  .footer-link-items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 14px;
    text-align: left;
    width: 200px;
    box-sizing: border-box;
  }
  
  .footer-link-items h2 {
    margin-bottom: 16px;
    color: #fff;
  }
  
  .footer-link-items a {
    color: #fff;
    text-decoration: none;
    margin-bottom: 0.5rem;
    transition: 0.3 ease-out;
  }
  
  .footer-link-items a:hover {
    color: #e9e9e9;
    transition: 0.3 ease-out;
  }
  
  .social-icon-link {
    color: #fff;
    font-size: 24px;
  }
  
  .social-media {
    max-width: 1000px;
    width: 100%;
  }
  
  .social-media-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    max-width: 1000px;
    margin: 40px auto 0 auto;
  }
  
  .social-icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 240px;
  }
  
  .website-rights {
    color: #fff;
  }

  #social-icon {
    width: 30px;
    height: 30px;
  }
  
  #social-icon:hover {
    color: #00D9D9;
  }

  @media screen and (max-width: 820px) {
    .footer-links {
      padding-top: 2rem;
    }
  
    #footer-logo {
      margin-bottom: 2rem;
    }
  
    .website-rights {
      margin-bottom: 2rem;
    }
  
    .footer-link-wrapper {
      flex-direction: column;
    }
  
    .social-media-wrap {
      flex-direction: column;
    }
  }
  
  @media screen and (max-width: 480px) {
    .footer-link-items {
      margin: 0;
      padding: 10px;
      width: 100%;
    }
  }