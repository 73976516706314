input {
  width: 60%;
  height: 40px;
  font-size: 15px;
  font-weight: bold;
  outline: none;
  border:1px solid #fff;
  border-radius: 5px;
  border-style:solid;
  padding: 10px 10px;
}

textarea {
  width: 60%;
  font-size: 15px;
  font-weight: bold;
  outline: none;
  border:1px solid #fff;
  border-radius: 5px;
  border-style:solid;
  padding: 10px 10px;
}

label {
  font-size: 20px;
  font-weight: bold;
  color: #ffff;
}

.contact {
    background-color: #000000;
    padding: 10rem 0;
}

.contact-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    height: 90%;
    z-index: 1;
    width: 100%;
    max-width: 1300px;
    padding: 0 50px;
}
  
.contact-content {
    color: #fff;
    width: 100%;
}
  
.contact-content h1 {
    font-size: 3rem;
    font-weight: 700;
    background-color: #0CF0AF;
    background-image: linear-gradient(to top, #00D9D9 0%, #0CF0AF 100%);
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
    text-transform: uppercase;
    margin-bottom: 32px;
}
   
.contact-content p {
    margin-top: 1rem;
    font-size: 2rem;
    font-weight: 700;
}
  
.contact-btn {
    width: 70%;
    font-size: 1.8rem;
    font-weight: bold;
    color: #fff;
    background: #0CF0AF;
    background: -webkit-linear-gradient(to right, #00B7F8, #00D9D9, #0CF0AF);
    background: linear-gradient(to right, #00B7F8, #00D9D9, #0CF0AF);
    padding: 20px 60px;
    border: none;
    border-radius: 4px;
    margin-top: 2rem;
    cursor: pointer;
    position: relative;
    transition: all 0.35s;
    outline: none;
}
  
.contact-btn a {
    position: relative;
    z-index: 2;
    color: #fff;
    text-decoration: none;
}
  
.contact-btn:after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background: #00B7F8;
    transition: all 0.35s;
    border-radius: 4px;
}
  
.contact-btn:hover {
    color: #fff;
}
  
.contact-btn:hover:after {
    width: 100%;
}
  
.contact-form-container {
    text-align: center;
}
  
  /* Mobile Responsive */
  @media screen and (max-width: 1100px) {
    .contact-container {
      display: grid;
      grid-template-columns: 1fr;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin: 0 auto;
      height: 90%;
    }
  
    .contact-img-container {
      display: flex;
      justify-content: center;
    }
  
    .contact-img-card {
      height: 425px;
      width: 425px;
    }
  
    .contact-content {
      text-align: center;
      margin-bottom: 4rem;
    }
  
    .contact-content h1 {
      font-size: 2.5rem;
      margin-top: 2rem;
    }
  
    .contact-content h2 {
      font-size: 3rem;
    }
  
    .contact-content p {
      margin-top: 1rem;
      font-size: 1.5rem;
    }
}
  
@media screen and (max-width: 480px) {
    .contact-img-card {
      width: 250px;
      height: 250px;
    }
  
    .contact-content h1 {
      font-size: 2rem;
      margin-top: 3rem;
    }
    .contact-content h2 {
      font-size: 2rem;
    }
  
    .contact-content p {
      margin-top: 2rem;
    }
  
    .contact-btn {
      padding: 12px 36px;
      margin: 2.5rem 0;
    }
  }