.navbar {
   background: #1A1A1A; 
   height: 80px;
   display: flex;
   justify-content: center;
   align-items: center;
   font-size: 1.2rem;
   position: sticky;
   top: 0;
   z-index: 999;
}

.navbar-container {
    display: flex;
    justify-content: space-between;
    height: 80px;
    z-index: 1;
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
    padding: 0 auto;
}

#navbar-logo {
    background-color: #0CF0AF;
    background-image: linear-gradient(to top, #00B7F8, #00D9D9, #0CF0AF);
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
    display: flex;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    font-weight: bold;
}

.navbar-menu {
    display: flex;
    align-items: center;
    list-style: none;
}

.navbar-item {
    height: 80px;
}

.navbar-link {
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    text-decoration: none;
    height: 100%;
    transition: all 0.3s ease;
}

.navbar-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
    width: 100%;
}

#contact-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    padding: 10px 20px;
    height: 100%;
    width: 100%;
    border: none;
    outline: none;
    border-radius: 10px;
    background: #0CF0AF;
    background: -webkit-linear-gradient(to right, #00B7F8, #00D9D9, #0CF0AF);
    background: linear-gradient(to right, #00B7F8, #00D9D9, #0CF0AF);
    color: #fff;
    transition: all 0.3s ease;
}

#contact-btn:hover {
    background: #0CF0AF;
    transition: all 0.1s ease-out;
}

#icon-fimail {
    padding-right: 3px;
}

.navbar-link:hover {
    color: #0CF0AF;
    border-bottom: 2px solid #0CF0AF;
    transition: all 0.2s ease;
}

.navbar-link::after {
    color: #0CF0AF;
    border-bottom: 2px solid #0CF0AF;
}

#navbar-logo-img{
    width: 70%;
    height: 70%;
    padding-right: 10px;
}

#navbar-logo-img:hover {
    -webkit-transform:rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
    transition: all 0.5s ease;
}

@media screen and (max-width: 960px) {
    .navbar-container {
      display: flex;
      justify-content: space-between;
      height: 80px;
      z-index: 1;
      width: 100%;
      max-width: 1300px;
      padding: 0;
    }

    .navbar-menu {
        display: grid;
        grid-template-columns: auto;
        margin: 0;
        width: 100%;
        position: absolute;
        top: -1000px;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: -1;
        background: #1A1A1A;
    }

    .navbar-menu.active {
        background: #1A1A1A;
        top: 100%;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 99;
        height: 60vh;
        font-size: 1.6rem;
    }

    #navbar-logo {
        padding-left: 25px;
    }

    .navbar-toggle .bar {
        width: 25px;
        height: 3px;
        margin: 5px auto;
        transition: all 0.3s ease-in-out;
        background: #fff;
    }

    .navbar-item {
        width: 100%;
        
    }

    .navbar-link {
        text-align: center;
        padding: 1rem;
        width: 100%;
        display: table;
    }

    .navbar-btn {
        padding-bottom: 2rem;
    }

    #contect-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80%;
        height: 80px;
        margin: 0;
    }

    #mobile-menu {
        position: absolute;
        top: 20%;
        right: 5%;
        transform: translate(5%, 20%);
    }

    .navbar-toggle .bar {
        display: block;
        cursor: pointer;
    }

    #mobile-menu.is-active .bar:nth-child(2) {
        opacity: 0;
    }
    
    #mobile-menu.is-active .bar:nth-child(1) {
        transform: translateY(8px) rotate(45deg);
    }
    
    #mobile-menu.is-active .bar:nth-child(3) {
        transform: translateY(-8px) rotate(-45deg);
    }
}