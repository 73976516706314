.program {
    background: #131313;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 10rem 0;
}
  
.program h1 {
    background-color: #ff8177;
    background-image: linear-gradient(to right, #ff0844 0%, #f7673c 100%);
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
    margin-bottom: 5rem;
    font-size: 2.5rem;
}
  
.program-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
  }
  
.program-card {
    margin: 10px;
    height: 425px;
    width: 300px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
    background-image: linear-gradient(to right, #00dbde 0%, #fc00ff 100%);
    transition: 0.3s ease-in;
  }
  
.program-card:nth-child(2) {
    background: #1fa2ff; /* fallback for old browsers */
    background: -webkit-linear-gradient(
      to right,
      #a6ffcb,
      #12d8fa,
      #1fa2ff
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
      to right,
      #a6ffcb,
      #12d8fa,
      #1fa2ff
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  }
  
.program-card:nth-child(3) {
    background-image: linear-gradient(
      -225deg,
      #231557 0%,
      #44107a 29%,
      #ff1361 67%,
      #fff800 100%
    );
  }
  
.program-card h2 {
    text-align: center;
  }
  
.program-card p {
    text-align: center;
    margin-top: 24px;
    font-size: 20px;
  }
  
.program-btn {
    display: flex;
    justify-content: center;
    margin-top: 16px;
}
  
.program-card button {
    color: #fff;
    padding: 14px 24px;
    border: none;
    outline: none;
    border-radius: 4px;
    background: #131313;
    font-size: 1rem;
}
  
.program-card button:hover {
    cursor: pointer;
}
  
.program-card:hover {
    transform: scale(1.075);
    transition: 0.3s ease-in;
    cursor: pointer;
}
  
@media screen and (max-width: 1300px) {
    .program-wrapper {
      grid-template-columns: 1fr 1fr;
    }
  }
  
@media screen and (max-width: 768px) {
    .program-wrapper {
      grid-template-columns: 1fr;
    }
}