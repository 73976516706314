.game {
    background-color: #43053e;
    padding: 10rem 0;
}

.game-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    height: 90%;
    z-index: 1;
    width: 100%;
    max-width: 1300px;
    padding: 0 50px;
}
  
.game-content {
    color: #fff;
    width: 100%;
}
  
.game-content h1 {
    font-size: 2rem;
    background-color: #fe3b6f;
    background-image: linear-gradient(to top, #ff087b 0%, #ed1a52 100%);
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
    text-transform: uppercase;
    margin-bottom: 32px;
}
  
.game-content h2 {
    font-size: 4rem;
    background: #ff8177; /* fallback for old browsers */
    background: -webkit-linear-gradient(
      to right,
      #9114ff,
      #da22ff
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
      to right,
      #8f0eff,
      #da22ff
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
}
  
.game-content p {
    margin-top: 1rem;
    font-size: 2rem;
    font-weight: 700;
}
  
.game-btn {
    font-size: 1.8rem;
    background: #833ab4;
    background: -webkit-linear-gradient(to right, #fcb045, #fd1d1d, #833ab4);
    background: linear-gradient(to right, #fcb045, #fd1d1d, #833ab4);
    padding: 20px 60px;
    border: none;
    border-radius: 4px;
    margin-top: 2rem;
    cursor: pointer;
    position: relative;
    transition: all 0.35s;
    outline: none;
}
  
.game-btn a {
    position: relative;
    z-index: 2;
    color: #fff;
    text-decoration: none;
}
  
.main-btn:after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background: #ff1ead;
    transition: all 0.35s;
    border-radius: 4px;
}
  
.game-btn:hover {
    color: #fff;
}
  
.game-btn:hover:after {
    width: 100%;
}
  
.game-img-container {
    text-align: center;
}
  
.game-img-card {
    margin: 10px;
    height: 500px;
    width: 500px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
    background-image: linear-gradient(to right, #00dbde 0%, #fc00ff 100%);
}
  
  
#card-2 {
    background: #ff512f;
    background: -webkit-linear-gradient(to right, #dd2476, #ff512f);
    background: linear-gradient(to right, #dd2476, #ff512f);
}
  
  /* Mobile Responsive */
  @media screen and (max-width: 1100px) {
    .game-container {
      display: grid;
      grid-template-columns: 1fr;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin: 0 auto;
      height: 90%;
    }
  
    .game-img-container {
      display: flex;
      justify-content: center;
    }
  
    .game-img-card {
      height: 425px;
      width: 425px;
    }
  
    .game-content {
      text-align: center;
      margin-bottom: 4rem;
    }
  
    .game-content h1 {
      font-size: 2.5rem;
      margin-top: 2rem;
    }
  
    .game-content h2 {
      font-size: 3rem;
    }
  
    .game-content p {
      margin-top: 1rem;
      font-size: 1.5rem;
    }
}
  
@media screen and (max-width: 480px) {
    .game-img-card {
      width: 250px;
      height: 250px;
    }
  
    .game-content h1 {
      font-size: 2rem;
      margin-top: 3rem;
    }
    .game-content h2 {
      font-size: 2rem;
    }
  
    .game-content p {
      margin-top: 2rem;
    }
  
    .game-btn {
      padding: 12px 36px;
      margin: 2.5rem 0;
    }
  }
  